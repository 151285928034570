@import "variables";
@import "_wrap-padding.scss";

// ::-webkit-scrollbar {
//   width: 10px;
// }

// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//   border-radius: 5px;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 5px;
//   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.7);
// }

body,
html {
  font-family: $font-family-base !important;
  font-size: $font-size-base;
  // padding-top: 45px;
  background-color: #f5f5f5 !important;
  // background-color: black;
  overflow: auto !important;
  overflow-x: hidden;
  width: 100% !important;
  height: auto !important;
  color: #4f4f4f;
}

.beshop-box-shadow-block {
  box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.beshop-block-product {
  width: 204px !important;
  margin: 20px 7px !important;

  .wrap-imageProduct {
    width: 100%;
    padding-top: 100%;
    position: relative;
    cursor: pointer;

    .yours-custom-class {
      height: 100%;
      h3 {
        height: auto;
        img {
          position: absolute;
          inset: 0;
          object-fit: scale-down;
        }
      }
    }
  }
}

.btn-outline--red {
  border: 1px solid #b71b4f;
  background-color: #b71b4f13;
  color: #b71b4f;
  padding: 4px 8px;
}

.btn-outline--green {
  border: 1px solid #1bb76e;
  background-color: #1bb76e0e;
  color: #1bb76e;
  padding: 4px 8px;
}

.btn-outline--gray {
  border: 1px solid #bbb !important;
  color: #4f4f4f !important;
  padding: 4px 8px !important;
  background-color: #f5f5f525 !important;
}

.beshop-btn-primary {
  background-color: #ed2049 !important;
  color: #fff !important;
  border-color: #ed2049 !important;
}

.MuiButtonBase-root:focus {
  outline: 0 !important;
}

.MuiButtonBase-root {
  font-family: "Myfont" !important;
}

a {
  color: #4f4f4f;
}

a:hover {
  color: #ed2049;
}

.price-color {
  color: #ed2049;
}

.btn {
  border-radius: 0;
}

.hide {
  display: none;
}

.cursor-poiter {
  cursor: pointer;
}

.cursor-poiter:hover {
  color: #ed2049 !important;
}

/*top bar*/

.topbar {
  background: #2e95b4;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
}

.topbar-nav li a {
  color: #fff;
}

.navbar-main {
  background: #ffffff !important;
  box-shadow: 0px 1px 3px #ddd;
  padding: 0 10px;
  top: 35px !important;
}

.navbar-brand {
  font-size: 1.6rem;
  font-weight: bold;
  color: #ec756b !important;
}

/*end top bar*/

#root {
  margin-top: 1px;
}

/*navmain*/

.navbar-toggler {
  border: none;
}

/*end nav main*/

/*left menu*/

#col-left {
  padding: 0;
  position: relative;
}

.nav-inner {
  position: sticky;
  top: 100px;
}

.nav-left li a {
  text-transform: uppercase;
  font-size: 12px;
  color: #333;
}

.nav-left .active {
  background: #fff;
  color: #2e95b4;
}

.nav-left li {
  width: 100%;
}

/*end left menu*/

/*cat*/

.cat-title {
  font-size: 12px;
  text-transform: uppercase;
}

/*end cat*/

/*module*/

.module-title {
  font-size: 1.1rem;
  background: #eee;
  padding: 10px 10px;
  font-weight: bold;
}

.head-title {
  font-size: 12px;
  margin: 0 15px;
  padding: 10px 0px;
  text-transform: uppercase;
  color: #666666;
}

/*end module*/

/*product*/

.card {
  border-radius: 0 !important;
  border: none !important;
  margin-bottom: 15px;
  background: #f5f5f5 !important;
}

.product-img {
  border-radius: 0 !important;
}

.product-item .card-body {
  padding: 5px;
}

.product-title {
  font-size: 14px;
}

.product-price {
  font-size: 12px;
}

.product-price span:last-child {
  color: #c1175a;
  font-weight: bold;
}

.product-price span:first-child {
  color: #999999;
  text-decoration: line-through;
}

.shock {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  background: #c1175a;
  font-size: 11px;
  color: #fff;
  padding: 2px 5px;
  font-weight: bold;
}

/*footer tab*/

.footer-tab {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: #fff;
  width: 100%;
}

.footer-tab .btn {
  font-size: 12px;
  color: #333;
}

.footer-tab i {
  font-size: 16px;
}

.footer-tab .active {
  color: #2e95b4;
}

/*tabbar*/

.price-color,
.text-red {
  color: #ed2049;
}

.text-blue {
  color: #205ee4 !important;
}

.text-green {
  color: #1bb76e !important;
}

.border-green {
  border-color: #1bb76e !important;
}

.border-blue {
  border-color: #205ee4 !important;
}

.hide-search {
  display: none;
}

.tabbar .nav {
  flex-wrap: nowrap;
  overflow: auto;
  white-space: nowrap;
}

.tabbar .nav li a {
  color: #333;
  text-transform: uppercase;
  font-size: 12px;
}

.tabbar .nav .active {
  color: #2e95b4;
}

/*detail*/

.path {
  margin-bottom: 0px;
}

.detail-title {
  font-size: 1.5rem;
  color: #000;
}

.detail-price {
  margin-bottom: 15px;
}

.detail-price span:first-child {
  color: #2e95b4;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 30px;
}

.detail-price span:last-child {
  color: #999999;
  text-decoration: line-through;
}

.detail-color {
  list-style: none;
  padding: 0;
}

.detail-color li {
  display: inline;
}

.detail-color .active {
  border: 1px #ff0000 solid !important;
}

.detail-color img {
  width: 70px;
}

.detail-info {
  padding: 15px;
  background: #f5f5f5;
}

.detail-info li {
  margin-left: 10px;
}

.detail-more {
  background: #fff;
  margin-top: 15px;
}

.detail-more img {
  margin-bottom: 5px;
}

.detail-action {
  margin-bottom: 15px;
}

.item-size {
  cursor: pointer;
}
.item-size:focus,
.item-size:active,
.item-size:visited {
  border: 1px #ff0000 solid !important;
}

/*cart*/
.remove_cart {
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 231;
  padding: 5px;
  background: #ff0000;
  color: #ffffff;
  cursor: pointer;
}
.cart-qua-container {
  margin: 5px 0px;
}

.cart-qua {
  flex: 0 !important;
  width: 50px !important;
  text-align: center;
}

.cart-sum {
  font-size: 1.1rem;
}

.cart-total {
  color: #2e95b4;
  font-weight: bold;
  font-size: 20px;
}

.shipinfo {
  font-weight: bold;
  font-size: 12px;
  color: #666666;
}

.cart-img {
  width: 150px;
}

/**/

// phan trang
.pagination > li {
  display: inline-block;
  padding-left: 0;
  margin-left: 5px;
}
.pagination > li {
  list-style: none;
  //border: 0.9px solid;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 10px 16px;
  line-height: 1.42857143;
  text-decoration: none;
  color: black;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > li.active > a {
  color: #fff;
  background-color: black;
  border-color: black;
}

.pagination > li.active > a:focus {
  outline: none;
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
  background-color: black;
  color: white;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  padding: 10px 16px;
}
.pagination > li:first-child > a:focus,
.pagination > li:first-child > span:focus {
  outline: none;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  margin-right: 0;
  padding: 10px 16px;
}

.pagination > li:last-child > a:focus,
.pagination > li:last-child > span:focus {
  outline: none;
}

.pagination > li.previous > a:hover {
  background: #fff;
  color: black;
}

.pagination > li.disabled {
  display: none;
}

/*responsive for defaulayout display*/

@media screen and (max-width: 1050px) and (min-width: 901px) {
  .wrap-padding-lf {
    padding: 0 12vw;
  }
}

@media screen and (max-width: 900px) and (min-width: 801px) {
  .wrap-padding-lf {
    padding: 0 13.5vw;
  }
}

// @media screen and (max-width: 800px) and (min-width: 768px) {
//   .wrap-padding-lf{
//     padding: 0 14vw;
//   }
// }

// @media (min-width: 768px) {
//   .col-1-5 {
//     width: 20%;
//     float: left;
//     position: relative;
//     min-height: 1px;
//     padding-right: 7px !important;
//     padding-left: 7px !important;
//     padding-bottom: 0 !important;
//   }
// }

// @media (max-width: 768px) {
//   .col-1-5 {
//     width: 50%;
//     float: left;
//     position: relative;
//     min-height: 1px;
//     padding-right: 7px !important;
//     padding-left: 7px !important;
//     padding-bottom: 0 !important;
//   }
//   .cart-img {
//     width: 80px;
//   }
// }

// @media (width: 768px) {
//   .col-1-5 {
//     width: 33.33%;
//     float: left;
//     position: relative;
//     min-height: 1px;
//     padding-right: 7px !important;
//     padding-left: 7px !important;
//     padding-bottom: 0 !important;
//   }
// }

.lazy-image {
  opacity: 1;
  object-fit: scale-down;
  transition: opacity 0.8s ease-in-out;
  -webkit-transition: opacity 0.8s ease-in-out;
  -moz-transition: opacity 0.8s ease-in-out;
}

.opacity {
  opacity: 1;
}

.image-gallery-image {
  height: 400px;
  width: 400px;
  overflow: hidden;
  margin: 0 auto;
}
.image-gallery-image img {
  max-width: 100%;
  max-height: 100%;
}
.image-gallery-thumbnail-inner img {
  width: 92px;
  height: 69px;
  object-fit: scale-down;
}

.submenu {
  display: none;
}
.border-1 {
  border-width: 3px;
}

.sumfee {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 25px;
}
.amount-error {
  display: none;
}
